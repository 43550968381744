import React, { useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import styles from './SocialProof.module.scss';

const SocialProof = ({
    title = '',
    linkTitle = '',
    appStoreLink = {},
    appStoreRating = null,
    googlePlayLink = {},
    googlePlayRating = null,
}) => {
    const [mounted, setMounted] = useState(false);
    const [isAppleDevice, setIsAppleDevice] = useState(false);

    const hasAppStore =
        !!appStoreLink.title || !!appStoreLink.href || !!appStoreRating;

    const hasGooglePlay =
        !!googlePlayLink.title || !!googlePlayLink.href || !!googlePlayRating;

    if (!hasAppStore && !hasGooglePlay) {
        return;
    }

    useEffect(() => {
        setMounted(true);

        if (typeof navigator !== 'undefined') {
            setIsAppleDevice(
                /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent)
            );
        }
    }, []);

    return (
        <div className={styles['SocialProof']}>
            <div className={styles['SocialProof__Container']}>
                <h2 className={styles['SocialProof__Title']}>{title}</h2>
                <div className={styles['SocialProof__Grid']}>
                    <div className={styles['SocialProof__Rating']}>
                        <Icon
                            icon={'star'}
                            dimensions={{ width: '20px', height: '20px' }}
                        />
                        <span className={styles['SocialProof__Number']}>
                            {appStoreRating.toString().replace('.', ',')}
                        </span>
                        <span className={styles['SocialProof__OutOf']}>
                            / 5
                        </span>
                        <a
                            className={styles['SocialProof__Link']}
                            href={appStoreLink.href}>
                            {appStoreLink.title}
                        </a>
                    </div>
                    <div className={styles['SocialProof__Rating']}>
                        <Icon
                            icon={'star'}
                            dimensions={{ width: '20px', height: '20px' }}
                        />
                        <span className={styles['SocialProof__Number']}>
                            {googlePlayRating.toString().replace('.', ',')}
                        </span>
                        <span className={styles['SocialProof__OutOf']}>
                            / 5
                        </span>
                        <a
                            className={styles['SocialProof__Link']}
                            href={googlePlayLink.href}>
                            {googlePlayLink.title}
                        </a>
                    </div>
                    <span
                        className={styles['SocialProof__Button']}
                        aria-hidden={true}>
                        <a
                            className={styles['SocialProof__ButtonTitle']}
                            href={
                                isAppleDevice
                                    ? appStoreLink.href
                                    : googlePlayLink.href
                            }>
                            {linkTitle}
                        </a>
                        <a
                            className={styles['SocialProof__Icon']}
                            href={appStoreLink.href}>
                            <span className={'sr-only'}>
                                {appStoreLink.title}
                            </span>
                            <Icon icon={'apple'} />
                        </a>
                        <a
                            className={styles['SocialProof__Icon']}
                            href={googlePlayLink.href}>
                            <span className={'sr-only'}>
                                {googlePlayLink.title}
                            </span>
                            <Icon icon={'googlePlay'} />
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

SocialProof.propTypes = {};

export default SocialProof;
